//import NavBar from "./NavBar";
// import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import ImageVideoPlayer from "./ImgVideo";
import IrregularBoxes from "./cards";
import ReviewSlider from "./Review";

function Home() {
  return (
    <>
        <HeroSection/>
        <ImageVideoPlayer/>
        <IrregularBoxes/>
        <ReviewSlider/>
        <Footer/>

        
  </>
  );
}

export default Home;