// ReviewSlider.js
import React, { useState, useEffect } from 'react';
import './Review.css';

const reviews = [
  { id: 1, text: 'Great!', author: 'Ravi' },
  { id: 2, text: 'Super Service.. Good  Responsive...', author: 'Balu' },
  { id: 2, text: 'Super', author: 'Mahendra Mahi' },
  { id: 2, text: 'The service is good', author: 'M Tharun Kumar' },
  { id: 2, text: 'Excellent service.', author: 'Hima Sri' },
  { id: 2, text: 'Awesome service for customer', author: 'Pavan P' },
  { id: 2, text: 'Amazing service!', author: 'Muni Ram' }
  // Add more reviews as needed
];


const ReviewSlider = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Move to the next review
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000); // Change review every 5 seconds (adjust as needed)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="review-slider">
      <div className="review-text">
        <p>{reviews[currentReviewIndex].text}</p>
        <p className="author">- {reviews[currentReviewIndex].author}</p>
      </div>
    </div>
  );
};

export default ReviewSlider;
