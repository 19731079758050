import React from 'react';
import './About.css'; // Import the CSS file
import Footer from '../Home/Footer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import ReviewSlider from './Testimonal';

const AboutSection = () => {
  // const [activeImage, setActiveImage] = useState(null);

  const images = [
    { image: '/Images/about1.jpg', heading: 'Revitalize Your Ride' },
    { image: '/Images/about2.jpg', heading: 'Precision Auto Repairs' },
    { image: '/Images/about3.jpg', heading: 'Empowering Your Drive' },
    { image: '/Images/about4.jpg', heading: 'Revolutionize Your Ride' },
    { image: '/Images/about6.jpg', heading: 'Engineered Excellence' },
    { image: '/Images/about1.jpg', heading: 'Gear Up for Greatness' },
    { image: '/Images/about3.jpg', heading: 'Gear Up for Greatness' },
    { image: '/Images/about2.jpg', heading: 'Beyond Maintenance' },
    { image: '/Images/about4.jpg', heading: 'Revitalize Your Ride' },
  ];

  // const handleImageHover = (index) => {
  //   setActiveImage(index);
  // };

  const handleImageClick = (index) => {
    // Add logic to handle the click event, e.g., show a video popup
    console.log(`Image ${index + 1} clicked`);
  };

  return (
    <div>
    <div className="AboutSectionContainer">
      <div className="About">
        <div className='Ships'>About </div>
        <p className="Contentss">Professional car garage offering comprehensive services, from routine maintenance to complex repairs.Skilled technicians equipped with advanced tools ensure efficient and reliable automotive care.</p>
      </div>
      </div>

      {/* About Images */}
      <div className='subj'>
        <div className="image-grid2">
          {images.map((image, index) => (
            <div className="images-container" key={index}  onClick={() => handleImageClick(index)}>
              <img src={image.image} alt={` ${index + 1}`} className="grid-image" style={{ borderRadius: '20px' }} />
              <div className="image-text">
                <h3>{image.heading}</h3>
                <div className="image-icon">
                  <a href="https://instagram.com/carc_raft?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zDEqL0mOzzYwYLRSNagwTkpOsjRMNks2tTRMNE4ztzKoSDRMSkw1MDJNMUtLTU4xM_YSSE4sUkguSkwrUUhPLEpMTwUA_pgV_A&q=car+craft+garage&rlz=1C1CHBF_enIN1057IN1057&oq=carcraft+Garage&gs_lcrp=EgZjaHJvbWUqDwgBEC4YChivARjHARiABDIGCAAQRRg5Mg8IARAuGAoYrwEYxwEYgAQyDQgCEAAYhgMYgAQYigUyDQgDEAAYhgMYgAQYigUyDQgEEAAYhgMYgAQYigXSAQkxNDI1MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8"  rel="noopenernoreferrer">
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </div> {/* You can replace this with your desired icon */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ReviewSlider/>
      <Footer />
    </div>
  );
};

export default AboutSection;
