import React, { useState } from 'react';
import './CustomerReviews.css'; // Import your CSS file

const ReviewList = ({ reviews, onReviewClick }) => {
  return (
    <div className="reviews-list">
      {reviews.map((review, index) => (
        <div key={index} className="review" onClick={() => onReviewClick(review)}>
          <h3>{review.title}</h3>
          <p>{review.summary}</p>
        </div>
      ))}
    </div>
  );
};

const FullReview = ({ review }) => {
  return (
    <div className="full-review">
      <h3>{review.title}</h3>
      <p>{review.content}</p>
     <img className='Image' src={review.image}/>
      {/* Add image display logic here */}
    </div>
  );
};

const CustomerReviews = () => {
  const [selectedReview, setSelectedReview] = useState(null);

  const reviews = [
    { title: 'Ravi',  summary: 'Great!', content: 'Great!' },
    { title: 'Muni Ram',  summary: 'Amazing service!', content: 'Amazing service!' },
    { title: 'Balu', summary: 'Super Service...Good  Responsive... ', content: 'Super Service...Good  Responsive  ...' },
    { title: 'Mahindra Mahi', summary: 'Super', content: 'Super' },
    { title: 'Pavan', summary: 'Excellent service.', content: 'Excellent service.' },
    { title: 'Tharun Kumar', summary: 'The service is good', content: 'The service is good' },
    { title: 'Hima Sri', summary: 'Excellent service.', content: 'Excellent service.' },
    // Add more reviews as needed
  ];

  const handleReviewClick = (review) => {
    setSelectedReview(review);
  };

  return (
    <div className="reviews-container">
      <ReviewList reviews={reviews} onReviewClick={handleReviewClick} />
      {selectedReview && <FullReview review={selectedReview} />}
    </div>
  );
};

export default CustomerReviews;
