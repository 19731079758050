// GeneralService.js

import React from 'react';
import './StandardService.css';
import Footer from '../Home/Footer';

const StandardService = () => {
  const data = [
    {
      imageUrl: '/Images/Standard Service/AirFilter.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Air Filter</h3>
             <p>
            Air filter service is a crucial aspect of vehicle maintenance aimed at ensuring optimal engine performance and air quality. This service involves inspecting, cleaning, or replacing the air filter, which plays a vital role in preventing dust, dirt, and debris from entering the engine. A clogged or dirty air filter can reduce fuel efficiency, hinder engine performance, and lead to increased emissions.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/Brakeservices.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Brake Alignment</h3>
          <p> Brake service commonly includes the inspection and potential replacement of brake pads, rotors, calipers, and brake fluid. Proper alignment of these components is crucial for effective braking performance. Regular brake service helps prevent issues like uneven wear, brake noise, and brake fade, promoting the longevity and reliability of the braking system.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/coolantTopUp.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Coolant TopUp</h3>
          <p>  Our technician will check the current coolant level, inspect the condition of the coolant for signs of contamination or degradation, and add the necessary amount of coolant to reach the proper level. It's essential to use the manufacturer-recommended coolant type and adhere to the vehicle's maintenance schedule to prevent issues related to overheating and maintain the overall health of the engine cooling system..</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/Engine Oil.jpeg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Engine Oil Change</h3>
          <p> 
            The engine oil change service is a fundamental aspect of routine vehicle maintenance, vital for preserving the longevity and efficiency of the engine. During this service, the existing engine oil is drained, eliminating accumulated contaminants and debris. Simultaneously, the oil filter is replaced to ensure effective filtration in the new oil. Fresh, high-quality engine oil is then added, providing crucial lubrication to the engine's moving parts. This process not only reduces friction and wear but also contributes to optimal engine performance</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/lightcheckups.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Lights Check Up</h3>
          <p> This comprehensive inspection covers headlights, taillights, brake lights, turn signals, and other auxiliary lights. Technicians meticulously assess the alignment, brightness, and condition of headlights, confirming they provide optimal illumination for the driver. Taillights and brake lights undergo scrutiny to ensure they illuminate accurately during vehicle operation and braking. Turn signals are checked for proper activation and flashing, indicating the driver's intentions. </p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/OilFilter.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Oil Filter</h3>
          <p> Dental services typically pertain to oral health care for humans, not cars. If you meant to inquire about a different type of car service, please provide more details, and I'll do my best to assist you. Whether it's related to maintenance, repair, or any other automotive topic, feel free to specify your question, and I'll provide relevant information.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/SusupensionCheckUp.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Suspension Check Up</h3>
          <p>  Assessment of shock absorbers and struts for signs of leakage, wear, or damage that could affect ride comfort and handling.Inspection of coil or leaf springs to ensure they are in good condition and capable of supporting the vehicle's weight.Examination of bushings and joints for wear or deterioration, as these components play a key role in maintaining proper alignment and reducing vibrations.Examination of various linkages, such as sway bar links, to ensure they are intact and functioning correctly.</p>
        </div>
      ),
    },
    // Add more items as needed
  ];

  return (
    <div className='Standard'>
     

      {data.map((item, index) => (
        <div key={index} className={`image-content-box ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="image-containers">
            <img src={item.imageUrl} alt={`Left ${index + 1}`} className="zoomable-image" />
          </div>
          <div className="content-container">
            {item.content}
          </div>
        </div>
      ))}
      <Footer/>
    </div>
  );
};

export default StandardService;
