import Footer from "../Home/Footer";
import CustomerReviews from "./CustomerReveiws";

import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setFormData({ name: "", number: "", email: "", message: "" }); // Reset form data
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate number (exactly 10 digits)
    const numberValue = formData.number.trim(); // Remove leading/trailing spaces
    if (!/^\d{10}$/.test(numberValue)) {
      alert("Please enter exactly 10 digits for the number");
      return;
    }

    // Validate email (assuming it should end with "@gmail.com")
    if (!formData.email.endsWith("@gmail.com")) {
      alert("Please enter a valid Gmail address");
      return;
    }

    // Handle form submission logic here
    // You can send the form data to your backend or perform any other actions
    console.log("Form submitted:", formData);

    // Close the popup and reset form data after submission
    closePopup();
  };

  return (
    <div className="garage-container-fluid">
      <img
        className="garage-image"
        src="/Images/Contact.jpg"
        alt="Car Garage Image"
      />
      <div className="overlay"></div>
      <div className="content">
        <h1>Contact Us</h1>
        
        <button onClick={openPopup}>Call for a Service</button>
      </div>

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <form onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Number:
                <input
                  type="text"
                  name="number"
                  pattern="\d{10}"
                  maxLength="10"
                  value={formData.number}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  pattern="[a-zA-Z0-9._%+-]+@gmail\.com"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Message:
                <textarea
                  className="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                />
              </label>
              <button type="submit">Submit</button>
              <button className="close-button" onClick={closePopup}>
                Close
              </button>
            </form>
          </div>
        </div>
      )}

      <CustomerReviews />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d30445.6533970876!2d78.38214521703364!3d17.473747989382606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m3!3m2!1d17.4850048!2d78.41382399999999!4m5!1s0x3bcb91c6c591a3f7%3A0xa1bae025d6fecd63!2scar%20craft%20garage!3m2!1d17.4514431!2d78.3951872!5e0!3m2!1sen!2sin!4v1700560514354!5m2!1sen!2sin"
        width="100%"
        height="480"
        style={{ border: "0", marginTop: "" }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <Footer />
    </div>
  );
};

export default Contact;
