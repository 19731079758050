// Import React and other necessary modules
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";
// import { BsToggleOff } from "react-icons/bs";
import { RiSteering2Fill } from "react-icons/ri";

// NavBar component
function NavBar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);

  const handleClick = () => setClick(!click);
  const handleDropdown = () => setDropdown(!dropdown);

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <img src="/Images/Car Craft Logo (1).png" alt="Logo" />
          </NavLink>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/about"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                About
              </NavLink>
            </li>
            <li className="nav-item" onMouseEnter={handleDropdown} onMouseLeave={handleDropdown}>
              <NavLink
                exact
                to="/generalservice"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Services
              </NavLink>
              {dropdown && (
                <ul className="sub-menu">
                  <li className="nav-item">
                    <NavLink to="/generalservice" className="nav-links" onClick={handleClick}>
                      General Service
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/standardservice" className="nav-links" onClick={handleClick}>
                      Standard Service
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/comprehensiveservice" className="nav-links" onClick={handleClick}>
                    Universal Services
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/washing" className="nav-links" onClick={handleClick}>
                     Washing
                    </NavLink>
                  </li>
                  {/* Add more sub-menu items as needed */}
                </ul>
              )}
            </li>
            
            
            <li className="nav-item">
              <NavLink
                exact
                to="/contact"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Contact Us 
              </NavLink>
            </li>
            
            {/* Add Join and Login buttons */}
           
          </ul>
          <div className="nav-icon" onClick={handleClick} >
            { <RiSteering2Fill />}
           
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
