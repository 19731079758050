import React, { useState } from 'react';
import './Hero.css';

const HeroSection = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', number: '', email: '', message: '' });

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
    setFormData({ name: '', number: '', email: '', message: '' }); // Reset form data
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate number (exactly 10 digits)
    const numberValue = formData.number.trim(); // Remove leading/trailing spaces
    if (!/^\d{10}$/.test(numberValue)) {
      alert('Please enter exactly 10 digits for the number');
      return;
    }

    // Validate email (assuming it should end with "@gmail.com")
    if (!formData.email.endsWith('@gmail.com')) {
      alert('Please enter a valid Gmail address');
      return;
    }

    // Handle form submission logic here
    // You can send the form data to your backend or perform any other actions
    console.log('Form submitted:', formData);

    // Close the popup and reset form data after submission
    closePopup();
  };

  return (
    <div className="garage-container-fluid">
      <img className="garage-image" src="/Images/Garage.jpg" alt="Car Garage Image" />
      <div className="overlay"></div>
      <div className="content">
        <h1>Welcome to Carcraft</h1>
        <p>Explore our services and find the perfect solution for your car.</p>
        <button onClick={openPopup}>Call for a Service</button>
      </div>

      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <form onSubmit={handleFormSubmit}>
              <label>
                Name:
                <input type="text" name="name" value={formData.name} onChange={handleInputChange} />
              </label>
              <label>
                Number:
                <input
                  type="text"
                  name="number"
                  pattern="\d{10}"
                  maxLength="10"
                  value={formData.number}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  pattern="[a-zA-Z0-9._%+-]+@gmail\.com"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Message:
                <textarea className="Message" name="message" value={formData.message} onChange={handleInputChange} />
              </label>
              <button type="submit">Submit</button>
              <button className="close-button" onClick={closePopup}>
                Close
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeroSection;