import "./App.css";
import NavBar from "./components/Home/NavBar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
// import Footer from "./components/Home/Footer";
// import HeroSection from "./components/Home/HeroSection";
// import ImageVideoPlayer from "./components/Home/ImgVideo";
// import IrregularBoxes from "./components/Home/cards";
// import Review from "./components/Home/Review";
// import ReviewSlider from "./components/Home/Review";
import Home from "./components/Home/HomeMainPage";
import Contact from "./components/Contact/Contact";
import AboutPage from "./components/About/About";
import General from "./components/GeneralService/GeneralService";
import Comprehensive from "./components/Comprehensiveservice/Comprehensive";
import StandardService from "./components/StandardService/StandardService";
import Washing from "./components/Washing/Washing";

function App() {
  return (
    <>
      <Router>
        <NavBar/>
      <Routes>
        <Route exact path='/' element={<Home/>}/>
        <Route exact path='/about' element={<AboutPage/>}/>
        <Route  path='/contact' element={<Contact/>}/>
        <Route  path='/generalservice' element={<General/>}/>
        <Route  path='/standardservice' element={<StandardService/>}/>
        <Route  path='/comprehensiveservice' element={<Comprehensive/>}/>
        <Route  path='/washing' element={<Washing/>}/>

        </Routes>
      </Router>
  </>
  );
}

export default App;