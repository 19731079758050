// GeneralService.js

import React from 'react';
import './GeneralService.css';
import Footer from '../Home/Footer';

const GeneralService = () => {
  const data = [
    {
      imageUrl: '/Images/GeneralService/GeneralService.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>General Service</h3>
          <p>Thorough inspection of various components such as the engine, transmission, brakes, suspension, and steering. Fluid levels are checked and topped up as needed, including engine oil, transmission fluid, brake fluid, and coolant. Air and fuel filters may be replaced, and the battery is inspected for signs of wear. Tire pressure and tread depth are assessed, and wheel alignment and balancing may be performed for even tire wear and better fuel efficiency. Brake components are inspected, and worn pads or rotors may be replaced. The exhaust system is examined for leaks or damage.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/GeneralService/ClutchWork.png',
      content: (
        <div className="vibrate-on-hover">
          <h3>Clutch Work</h3>
          <p> A thorough inspection is the initial step, aiming to identify signs of wear or damage. If components such as the clutch disc, pressure plate, or throw-out bearing are found to be worn or damaged, replacement becomes necessary. Additionally, adjustments to the clutch may be required for optimal performance, particularly in vehicles with adjustable clutch mechanisms. Flywheel resurfacing may be undertaken if the flywheel, which engages with the clutch, shows signs of scoring or wear. In hydraulic clutch systems, attention is given to the hydraulic fluid, with checks and potential bleeding to ensure proper clutch engagement.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/GeneralService/Brakealignment.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Brake Alignment</h3>
          <p> Brake service includes a thorough visual inspection of brake pads, rotors, calipers, and brake lines. The brake pads may need replacement if they are worn, and rotors might require resurfacing or replacement if they show signs of scoring or uneven wear. Calipers are inspected for proper function, and any leaks are addressed. Additionally, lubrication of caliper slide pins may be part of the service. Checking brake fluid levels and quality is essential, and if necessary, brake fluid replacement is performed. It's crucial to adhere to the manufacturer's guidelines and specifications outlined in the vehicle's service manual to ensure proper brake performance and overall safety on the road.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/GeneralService/DentalService.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Dental Service</h3>
          <p> Dental services typically pertain to oral health care for humans, not cars. If you meant to inquire about a different type of car service, please provide more details, and I'll do my best to assist you. Whether it's related to maintenance, repair, or any other automotive topic, feel free to specify your question, and I'll provide relevant information.</p>
        </div>
      ),
    },
    // Add more items as needed
  ];

  return (
    <div className='General'>
      <div >
        <div className="Generalservice">
          <div className='Services'>Services</div>
          <p className="Contentss">Professional car garage offering comprehensive services, from routine maintenance to complex repairs. Skilled technicians equipped with advanced tools ensure efficient and reliable automotive care.</p>
        </div>
      </div>

      {data.map((item, index) => (
        <div key={index} className={`image-content-box ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="image-container">
            <img src={item.imageUrl} alt={`Left ${index + 1}`} className="zoomable-image" />
          </div>
          <div className="content-container">
            {item.content}
          </div>
        </div>
      ))}
      <Footer/>
    </div>
  );
};

export default GeneralService;
