import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './cards.css'; // Import the CSS file

const IrregularBoxes = () => {
  const boxesData = [
    {
      id: 1,
      image: '/Images/imgCard1.jpg',
      content: 'General Service',
      link:'http://localhost:3000/generalservice'
    },
    {
      id: 2,
      image: '/Images/standard.avif',
      content: 'Standard Service',
      link:'http://localhost:3000/standardservice'
    },
    {
      id: 3,
      image: '/Images/imgCard3.jpg',
      content: 'Comprehensive Service',
      link:"http://localhost:3000/comprehensiveservice"
    },
    // Add more boxes as needed
  ];

  return (
    <div className="container">
      {boxesData.map((box) => (
        <Link key={box.id} to={box.link} className="box">
          <img src={box.image} alt={`Box ${box.id}`} />
          <div className="content">{box.content}</div>
        </Link>
      ))}
    </div>
  );
};

export default IrregularBoxes;
