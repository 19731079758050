import React from 'react';
import './Footer.css'; 
// import { AiFillInstagram } from "react-icons/ai";

// import { AiFillTwitterCircle } from "react-icons/ai";
// import { FaFacebook ,FaWhatsapp } from "react-icons/fa";
// import { BsMessenger } from "react-icons/bs";

function handleSocialLinkClick(platform) {
  // Add your custom logic for handling the click event here
  console.log(`Clicked on ${platform}`);
}

function Footer() {
  return (
    <footer className="footer">
      <div className="footerContent">
        <div className="contentBox">
        <div className='logo'>
      <div>
      <img src="/Images/Car Craft Logo (1).png"alt="" width="200"height=""/>
      </div>
      <h6>100 Feet Road,<br></br>
        Madhapur, Hyderabad
      </h6>
    </div>
          
         
        </div>
        <div className="contentBox">
          <h3>Car Craft</h3>
          <ul className="social">
            <li><a href="/">Home</a></li>
            <li><a href="/generalservice">Service</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
        <div className="contentBox">
          <h3>Services</h3>
          <ul className="social">
            <li><a href="/generalservice">General Service</a></li>
            <li><a href="standardservice">Standard Service</a></li>
            <li><a href="/comprehensiveservice">Comprehensive Service</a></li>
            <li><a href="/washing">Washing</a></li>
          </ul>
        </div>
        <div className="contentBox">
          <h3>Follow Us</h3>
          <ul className="social">
          <li>
            <a href="https://instagram.com/carc_raft?utm_source=qr&igshid=MzNlNGNkZWQ4Mg==" target="_blank" rel="noopener noreferrer" onClick={() => handleSocialLinkClick('Instagram')}>
              Instagram
            </a>
          </li>
          <li>
            <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zDEqL0mOzzYwYLRSNagwTkpOsjRMNks2tTRMNE4ztzKoSDRMSkw1MDJNMUtLTU4xM_YSSE4sUkguSkwrUUhPLEpMTwUA_pgV_A&q=car+craft+garage&rlz=1C1CHBF_enIN1057IN1057&oq=carcraft+Garage&gs_lcrp=EgZjaHJvbWUqDwgBEC4YChivARjHARiABDIGCAAQRRg5Mg8IARAuGAoYrwEYxwEYgAQyDQgCEAAYhgMYgAQYigUyDQgDEAAYhgMYgAQYigUyDQgEEAAYhgMYgAQYigXSAQkxNDI1MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8"  rel="noopenernoreferrer" onClick={() => handleSocialLinkClick('Facebook')}>
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.google.com/search?gs_ssp=eJzj4tVP1zc0zDEqL0mOzzYwYLRSNagwTkpOsjRMNks2tTRMNE4ztzKoSDRMSkw1MDJNMUtLTU4xM_YSSE4sUkguSkwrUUhPLEpMTwUA_pgV_A&q=car+craft+garage&rlz=1C1CHBF_enIN1057IN1057&oq=carcraft+Garage&gs_lcrp=EgZjaHJvbWUqDwgBEC4YChivARjHARiABDIGCAAQRRg5Mg8IARAuGAoYrwEYxwEYgAQyDQgCEAAYhgMYgAQYigUyDQgDEAAYhgMYgAQYigUyDQgEEAAYhgMYgAQYigXSAQkxNDI1MWowajeoAgCwAgA&sourceid=chrome&ie=UTF-8"  rel="noopenernoreferrer" onClick={() => handleSocialLinkClick('WhatsApp')}>
              WhatsApp
            </a>
          </li>
        </ul>
        </div>
      </div>
      <div className="copyrights">
        <hr />
       <p> © Copyright Car Carft @ 2023 All Rights Reserved ..</p>
      </div>
    </footer>
  );
}

export default Footer;