// ReviewSlider.js
import React, { useState, useEffect } from 'react';
import './Testimonal.css';

const reviews = [
  {
    id: 1,
    text: 'Great experience working with the team. Their dedication and expertise exceeded our expectations!',
    author: 'Raj ',
    role: 'CEO, Car Craft',
    image: '/Images/ravi.jpg',
  },
  {
    id: 2,
    text: 'Raj and the team provided amazing service. They went above and beyond to ensure our project success.',
    author: 'Raj shekar',
    role: 'Founder, Car craft',
    image: 'Images/muni.png',
  },
  // Add more reviews as needed
];

const ReviewSlider = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Move to the next review
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000); // Change review every 5 seconds (adjust as needed)

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="review-slider">
      <div className="review-text">
        < img src={reviews[currentReviewIndex].image} alt={` ${reviews[currentReviewIndex].author}`}  className='Imagetest'/>
        <p>{reviews[currentReviewIndex].text}</p>
        <p className="author">
          - {reviews[currentReviewIndex].author}, {reviews[currentReviewIndex].role}
        </p>
      </div>
    </div>
  );
};

export default ReviewSlider;
