// ImageVideoPlayer.js
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './ImgVideo.css';

const ImageVideoPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handleTogglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="image-video-container">
      <div className="image-contain">
        <img src="/Images/CarCraft.jpg" alt="LeftSideImage" />
      </div>
      <div
        className="video-container"
        onMouseEnter={() => handleTogglePlay()}
        onMouseLeave={() => handleTogglePlay()}
      >
        <ReactPlayer
          url={"/Images/CarcraftVideo.mp4"}
          controls={true}
          playing={isPlaying}
          width="100%"
          height="100%"
        />
        {isPlaying ? null : <div className="play-icon">▶️</div>}
      </div>
    </div>
  );
};

export default ImageVideoPlayer;

