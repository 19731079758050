// GeneralService.js

import React from 'react';
import './Washing.css';
import Footer from '../Home/Footer';

const Washing = () => {
  const data = [
    {
      imageUrl: '/Images/Washing/Foam Wash.jpeg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Foam Wash</h3>
          <p>Foam car wash employs a specialized foam cannon or gun to apply a thick layer of foam onto the vehicle, serving as a pre-soak that loosens and encapsulates dirt. This gentle method reduces the risk of scratches, reaches into tight spaces, and enhances cleaning efficiency. After allowing the foam to dwell, a soft wash mitt is used to agitate and lift dirt before a thorough rinse.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Washing/InteriorCleaning1.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Interior Cleaning</h3>
          <p> Our skilled technicians then employ professional-grade vacuuming to thoroughly clean upholstery, carpets, and floor mats, lifting away dirt and allergens. Surfaces such as the dashboard, steering wheel, and door panels are expertly cleaned and conditioned, leaving them looking and feeling like new. Stains or spills are treated with care, and optional interior protectants can be applied to safeguard against future wear. </p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Washing/TeflonCoating1.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Teflon Coating</h3>
          <p> The result is a durable, high-gloss finish that not only shields the paint from environmental elements but also makes cleaning and maintenance easier. Teflon coating provides long-lasting protection against UV rays, oxidation, and other factors contributing to paint degradation, ensuring a lasting, showroom-quality appearance for your vehicle.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Washing/CeramicCoating.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Ceramic Coating</h3>
          <p>  Ceramic coatings offer a range of benefits, including enhanced durability, resistance to UV rays, oxidation, and environmental contaminants. The hydrophobic properties of ceramic coatings make the vehicle easier to clean, and they contribute to a long-lasting, high-gloss finish.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Washing/Antirust.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Underbody Antirust</h3>
          <p> 
            Underbody anti-rust service is a preventive automotive maintenance procedure designed to protect a vehicle's undercarriage from corrosion and rust. This service typically involves applying a specialized rust inhibitor or undercoating to the vulnerable metal surfaces beneath the vehicle. This protective coating forms a barrier against moisture, salt, and road debris, reducing the risk of rust formation. Particularly beneficial in areas with harsh winter conditions and exposure to road salts, the underbody anti-rust service helps extend the lifespan of the vehicle and preserves its structural integrity.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Washing/ACVents.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>AC Vents Cleaning</h3>
          <p> 
            Ac vents cleaning service is a specialized automotive maintenance offering focused on improving the air quality and efficiency of a vehicle's air conditioning system. During this service, skilled technicians clean and remove accumulated dust, debris, and contaminants from the air vents and ducts. This not only enhances the overall air circulation but also helps eliminate potential allergens and unpleasant odors.</p>
        </div>
      ),
    },
    // Add more items as needed
  ];

  return (
    <div className='Washing'>
      <div >
        <div className="WashingService">
          <div className='Wash'>Washing</div>
          <p className="Contentss">Professional car garage offering comprehensive services, from routine maintenance to complex repairs. Skilled technicians equipped with advanced tools ensure efficient and reliable automotive care.</p>
        </div>
      </div>

      {data.map((item, index) => (
        <div key={index} className={`image-content-box ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="image-containers">
            <img src={item.imageUrl} alt={`Left ${index + 1}`} className="zoomable-image" />
          </div>
          <div className="content-container">
            {item.content}
          </div>
        </div>
      ))}
      <Footer/>
    </div>
  );
};

export default Washing;
