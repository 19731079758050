// GeneralService.js

import React from 'react';
import './Comprehensive.css';
import Footer from '../Home/Footer';

const Comprehensive = () => {
  const data = [
    {
      imageUrl: '/Images/Comprehensive Service/WheelAlignment.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Wheel Alignment</h3>
          <p>The technician assesses the current alignment settings by checking the angles of the wheels, including toe, camber, and caster.If necessary, adjustments are made to align the wheels according to the vehicle manufacturer's specifications. This may involve adjusting the angles using specialized equipment.The condition of the tires is examined, and any irregular wear patterns are noted. Proper alignment helps distribute the load evenly on the tires, preventing premature wear.A test drive may be conducted to evaluate the vehicle's handling and ensure that the adjustments have been accurately made.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/ACFilter.avif',
      content: (
        <div className="vibrate-on-hover">
          <h3>AC Filter replacement</h3>
          <p> The technician examines the existing cabin air filter to assess its condition, checking for dirt, debris, and any signs of wear.If the filter is dirty or clogged, the technician removes the old cabin air filter from its housing.A new, clean cabin air filter is installed to ensure efficient filtration of dust, pollen, pollutants, and other airborne particles.The new filter enhances the quality of the air circulated through the vehicle's ventilation system, contributing to a healthier and more comfortable driving environment.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/Brakeservices.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Brake Service</h3>
          <p> Brake service includes a thorough visual inspection of brake pads, rotors, calipers, and brake lines. The brake pads may need replacement if they are worn, and rotors might require resurfacing or replacement if they show signs of scoring or uneven wear. Calipers are inspected for proper function, and any leaks are addressed. Additionally, lubrication of caliper slide pins may be part of the service. Checking brake fluid levels and quality is essential, and if necessary, brake fluid replacement is performed. It's crucial to adhere to the manufacturer's guidelines and specifications outlined in the vehicle's service manual to ensure proper brake performance and overall safety on the road.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/Engine Oil.jpeg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Engine Oil</h3>
             <p> 
            The engine oil change service is a fundamental aspect of routine vehicle maintenance, vital for preserving the longevity and efficiency of the engine. During this service, the existing engine oil is drained, eliminating accumulated contaminants and debris. Simultaneously, the oil filter is replaced to ensure effective filtration in the new oil. Fresh, high-quality engine oil is then added, providing crucial lubrication to the engine's moving parts. This process not only reduces friction and wear but also contributes to optimal engine performance</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/AirFilter.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Air Filter</h3>
            <p> 
            Air filter service is a crucial aspect of vehicle maintenance aimed at ensuring optimal engine performance and air quality. This service involves inspecting, cleaning, or replacing the air filter, which plays a vital role in preventing dust, dirt, and debris from entering the engine. A clogged or dirty air filter can reduce fuel efficiency, hinder engine performance, and lead to increased emissions.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/CoolantTopUp.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>CoolantTopUp</h3>
          <p> Technician will check the current coolant level, inspect the condition of the coolant for signs of contamination or degradation, and add the necessary amount of coolant to reach the proper level. It's essential to use the manufacturer-recommended coolant type and adhere to the vehicle's maintenance schedule to prevent issues related to overheating and maintain the overall health of the engine cooling system.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Standard Service/lightcheckups.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Light Check Up</h3>
          <p> This comprehensive inspection covers headlights, taillights, brake lights, turn signals, and other auxiliary lights. Technicians meticulously assess the alignment, brightness, and condition of headlights, confirming they provide optimal illumination for the driver. Taillights and brake lights undergo scrutiny to ensure they illuminate accurately during vehicle operation and braking. Turn signals are checked for proper activation and flashing, indicating the driver's intentions. </p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/OilFilter.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Oil Filter</h3>
          <p>  This service, the existing oil filter is removed, and the technician assesses its condition for any signs of clogging or wear. A new, high-quality oil filter is then installed to effectively trap impurities and contaminants from the engine oil. This process is crucial in maintaining clean oil circulation, preventing debris from causing damage to engine components, and optimizing overall engine health.</p>
        </div>
      ),
    },
    {
      imageUrl: '/Images/Comprehensive Service/SusupensionCheckUp.jpg',
      content: (
        <div className="vibrate-on-hover">
          <h3>Suspension CheckUp</h3>
          <p>Assessment of shock absorbers and struts for signs of leakage, wear, or damage that could affect ride comfort and handling.Inspection of coil or leaf springs to ensure they are in good condition and capable of supporting the vehicle's weight.Examination of bushings and joints for wear or deterioration, as these components play a key role in maintaining proper alignment and reducing vibrations.Examination of various linkages, such as sway bar links, to ensure they are intact and functioning correctly.</p>
        </div>
      ),
    },
    // Add more items as needed
  ];

  return (
    <div className='General'>
     

      {data.map((item, index) => (
        <div key={index} className={`image-content-box ${index % 2 === 0 ? 'even' : 'odd'}`}>
          <div className="image-containers">
            <img src={item.imageUrl} alt={`Left ${index + 1}`} className="zoomable-image" />
          </div>
          <div className="content-container">
            {item.content}
          </div>
        </div>
      ))}
      <Footer/>
    </div>
  );
};

export default Comprehensive;
